import React from 'react';
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from "gatsby"
import Heading from '../../../components/shared/heading'
import Text from '../../../components/shared/text'
//import Image from '../../../components/image'
import {GrapheneSectionWrap, SectionTitle, GrapheneContent } from './graphene.stc'
 
const Graphene = ({HeadingStyle, TextStyle, ProjectTypeHeading, ProjectTypeText, FullwidthBox, Controller, Scene}) => {
    const grapheneQueryData = useStaticQuery(graphql `
        query grapheneQuery {
            grapheneJson(id: {eq: "graphene-intro-content"}) {
                id
                title
                desc
                content
				features {
							  id
							  title
							  text
							  image {
								childImageSharp {
								  fluid(maxWidth: 1920, maxHeight: 1280, quality: 100) {
									...GatsbyImageSharpFluid_withWebp
									presentationWidth
									presentationHeight
								  }
								}
							  }
							}
            }
        }      
    `);
    const {title, desc, content, features} = grapheneQueryData.grapheneJson;
    return (
        <GrapheneSectionWrap>
            <div className="row">
                {(title || desc) && (
                    <div className="col-1 offset-1">
                        <SectionTitle>
                            {title && <Heading {...HeadingStyle}>{title}</Heading>}
                            {desc && <Text {...TextStyle}>{desc}</Text>}                        
                        </SectionTitle>
                    </div>
                )}
                {content && (
                    <div className="col-2 offset-1">
                        <GrapheneContent>
                            <Text>{content && content.map((text, i) => <p key={`text-${i}`}>{text}</p>)}</Text>
                        </GrapheneContent>
                    </div>
                )}
            </div>

        </GrapheneSectionWrap>
    )
}

Graphene.propTypes = {
    HeadingStyle: PropTypes.object,
    TextStyle: PropTypes.object
}

Graphene.defaultProps = {
    HeadingStyle: {
        fontSize: '40px',
        texttransform: 'capitalize',
        lineHeight: '55px',
        color: 'primary',
        fontweight: 600,
        responsive: {
            medium: {
                fontSize: '24px',
                lineHeight: 'initial'
            },
            small: {
                fontSize: '25px',
                lineHeight: '35px'
            }
        }
    },
    TextStyle: {
        lineHeight: '26px'
    },
    ProjectTypeHeading: {
        as: 'h6',
        color: 'primary',
        fontSize: '12px',
        fontweight: 700,
        letterspacing: '2px',
        mb: '12px'
    },
    ProjectTypeText: {
        m: 0,
        fontSize: '12px',
        color: '#000000',
        letterspacing: '1px'
    }
}

export default Graphene;