import styled from 'styled-components';
import {device} from '../../../theme'

export const GrapheneSectionWrap = styled.div `
    padding: 130px 0;
    .section-pt-md{
        padding-top: 70px;
    }
    @media ${device.xlarge}{
        .col-2.offset-1{
            margin-left: 20vw;
        }
    }
    @media ${device.medium}{
        padding: 70px 0;
        .col-1.offset-1, .col-2.offset-1 {
            max-width: 80vw;
            min-width: 80vw;
            flex: 0;
            margin-left: 10vw;
        }
        .section-pt-md{
            padding-top: 15px;
        }
        .project-type{
            flex-basis: 50%;
            max-width: 50%;
            width: 50%;
            margin: 30px 0;
        }
    }
    @media ${device.small}{
        padding: 10vw 0;
        .project-type{
            flex-basis: 100%;
            max-width: 100%;
            width: 100%;
            margin: 0;
            padding: 15px 0;
        }
    }
`;

export const SectionTitle = styled.div `
    margin-top: -10px;
`;

export const GrapheneContent = styled.div `
    margin-top: -7px;
    margin-bottom: -18px;
`;

export const ProjectTypeList = styled.ul `
    margin: 0;
    padding-left: 17px;
`;


export const BannerWrap = styled.div `
    .gatsby-image-wrapper{
        z-index: -1;
    }
`;

export const ProjectDetailsWrap = styled.div `
    background: #f8f8f8;
    .section-ptb-xl {
        padding: 130px 0;
        @media ${device.medium}{
            padding: 70px 0;
        }
        @media ${device.small}{
            padding: 10vw 0;
        }
    }
    .col-2.offset-1 {
        @media ${device.xlarge}{
            margin-left: 20vw;
        }
        @media ${device.small}{
            width: 80vw;
            margin-left: 10vw;
        }
    }

`;

export const ProjectHeaderWrap = styled.div `

`;

export const ProjectHeaderMeta = styled.div `
    margin-top: 80px;
    @media ${device.large}{
        margin-top: 100px;
    }
    @media ${device.small}{
        margin-top: 5px;
        margin-bottom: 38px;
    }
`;

export const ProjectType = styled.div `
    margin-top: 30px;
`;

export const ProjectFeatureWrap = styled.div `
    .rn-project-meta-inner{
        margin-top: -10px;
    }
    .rn-project-content {
        margin-top: -7px;
        margin-bottom: -18px;
    }
`;

export const FullwidthBox = styled.div `
    padding-left: 80px;
    padding-right: 80px;
    @media ${device.xlarge}{
        padding-left: 10vw;
        padding-right: 10vw;
    }
`;


export const NextProjectWrap = styled.div `
    background-color: #f8f8f8;
    padding: 130px 0;
    @media ${device.medium}{
        padding: 70px 0;
    }
    @media ${device.small}{
        padding: 10vw 0;
    }
    .col-1.offset-1{
        @media ${device.medium}{
            max-width: 40vw;
            flex: 0 0 40vw;
        }
    }
`;