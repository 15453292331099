import React from "react"
import SEO from '../components/seo';
import Layout from '../containers/layout/layout'
import Banner from '../containers/projects/banner'
import Graphene from '../containers/projects/graphene'
import Project from '../containers/projects/project'

export default () => (
    <Layout headerLayout={2}>
        <SEO title="О Графене"/>
        <Banner/>
		<Graphene/>
        <Project/>
    </Layout>
)
